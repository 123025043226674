/* Created by Vincenzo Centofanti  
   Pagina stile ref:  LOGIN.jsx
   Pagina stile ref:  LOGOUT.jsx
   Pagina stile ref:  SINGUP.jsx*/

#section-login{
  height: 100%;
  margin: 70px auto;
}
#section-login-grid{
  width: 600px;
  margin: 40px auto; 
}
/* FINE LOGIN*/