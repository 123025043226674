/* Created by Vincenzo Centofanti
   Pagina stile ref:  CONFIGURATION.jsx
   Pagina stile ref:  OBJCUSTOMIZATION.jsx
   Pagina stile ref:  SINGUP.jsx*/


/* CONFIGURATION */

#hideEditMenu{
    display: none;
    margin:0px 0px 0px 0px;
    text-align: center;
    font-size:12px;
}
#section-cfg-label-title{
    float: right;
    margin:10px;
}

#section-cfg-edit{
    width: 95%;
}

#hidePanelPlay{
    display: none;
    margin:0px 0px 0px 0px;
}

#section-config-barplayer{
 margin:0px 0px 0px 0px;
 float:left;
}
#section-config-barlabel{
 margin:0px 0px 0px 0px;
}

#iframebottomleft{
 float: right;
 margin: 5px 0px 0px 0px;
 font-size: 12px;
}

#hidePanelTime{
    display: none;
}

.rowTop{
    margin:0px 0px 0px 0px;
}

.rowBottom{
    margin:-28px 0px 0px 0px;
}

#ModalTempSetting{
  margin:auto !important;
  background: #283E51;
}

#ModalContentSetting{
  margin:auto !important;
  background: #283E51;
}



#iframebottomright{
    margin: 0px 0px 0px 0px;
    padding: 0px;
    font-size:15px;
    text-overflow: ellipsis;
    text-align: left;
    vertical-align: top;
}

#div-on-the-top{
    background-color: rgba(0,0,0,0);
    margin: 0px 0px 0px 0px;
    padding: 0px;
    width:100%;
    height:5%;
    vertical-align: top;
    text-align: right;
}

#div-on-the-left{
    margin: 0px 5px 5px 5px;
    padding: 0px;
    width:80%;
    vertical-align: top;
    text-align: left;
    vertical-align: top;
}


#boxpanel{
/*  background-color: rgba(0,0,0,0);
    margin: 0px 0px 0px 0px;
    padding: 0px;
    width:90%;
    height:10%;*/
}


#iframeObj{
  /*width: 56.25vw;
  height: 47.75vh;*/
  width: 54.25vw;
  height: calc(56.25vw * (9/16)); /*56.25 : 16 = x : 9*/
  /*width: 55vw;
  height: 60vh;*/
  border: none;
}

#section-edit-card-desc {
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*color:#cd7f32; /*BRONZE*/
}
#section-edit-card-link {
  font-size: 10px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



/* OBJCUSTOMIZATION */
.listobj{
    color: #FFFFFF;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    overflow: auto !important;
    height: 410px;
}

.listitemheaderobj{
    /*color: orange !important;*/
    margin: 0px 0px 0px 0px;
    padding: 0px;
    font-size:18px;
    text-overflow: ellipsis;
    text-align: left;
}

.listitemobj{/**/}

#resourcename{
    margin:5px 0px 0px 0px;
    color: rgba(0,0,0,1);
    font-size:14px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
}

#buttonsdiv{
    margin:0px 0px 0px 0px;
    text-align: right;
    font-size:12px;
}

.componentOnIframeClass{
/*  background-color: rgba(0,0,0,0.5);
    margin: 0px 0px 0px 0px;
    padding: 0px;
    text-align: left;
    font-size:14px;
    height: 100%;*/
}

#menuVerticalLeft{
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height:99%;
  /*height:35vh;per pannelli futuri*/
}

#menuVerticalRight{
  overflow-y: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height:99%;
  /*height:35vh;per pannelli futuri*/
}

.accordioncontent{
  margin:0px 0px 0px 10px;
  width:90%;
  text-align: left;
}

#timelinediv{
    color: #FFFFFF;
}

#videoeventdiv{
    font-size:14px;
	color: #FFFFFF;
	margin:10px 10px 10px 10px;
}

#section-config-video{
  background: #c8c6c6;
  overflow: hidden;
  position: relative;
  /*width:60%;*/
  /*height:550px;*/
  -ms-zoom: 0.75;
  margin:0px 0px 0px 0px;
}

#section-config-videoH{
  background: transparent;
  overflow: hidden;
  width:90%;
  -ms-zoom: 0.75;
  margin:auto;
}

#iframebox{
  width: 100%;
  border: none;
}

#section-config-label-title{
  margin:-30px 0px 0px 0px;
  float:right;
  text-overflow: ellipsis;
  text-align: left;
  z-index:1;
}

#section-config-menuAccScrollSx{
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  /*width: 90%; */
  text-overflow: ellipsis;
  height: 549px;
  background: #ebebf0;
/*  margin: 30px 0px 0px 0px;*/
  margin: 30px -20px 0px 0px;
  border-radius: 2px;
}

#dimmerbox{
  width:14%;
}

#section-config-AccordionHotspot{
  font-size: 12px;
  white-space: nowrap;
  width: 12em;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-y: auto;
  overflow-x: hidden;
  letter-spacing: 0px;
  text-align:left;
  margin-right: 5px;
}
#section-config-PopHotspot{
  margin-bottom: 10px;
  border-radius: 0px;
  background:#f7f7fa;
  text-align: left;
  width: 95%;
}

#section-config-IconAccordion{
  /*float:right;*/
}

#section-config-iconGold{
  color: #ac975e; /*GOLD*/
}

#section-config-menutabular{
  border: none;
  border-radius: 2px;
  background-color: #ebebf0;
  color: #767676;
  margin: 10px 0px 0px 0px;
}

#section-config-icontextGold{
 color: #ac975e; /*GOLD*/
 font-size: 14px;
}

#section-config-MainMenuTabular{
  margin: 0px 0px 0px 0px;
}

#section-shapecolor-SketchPicker{
    width: 180px;
    padding: 3px 3px 0px;
    box-sizing: initial;
    /* background: rgb(255, 255, 255); */
    border-radius: 4px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px; */
}

#ModalTemp{
  width: 500px;
  margin-top: -90px;
  position: absolute;
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


#footer{
    display: none;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 160px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 14px;
    font-family: 'OpenSansLight', sans-serif;
    background: #333334;
    color: rgba(255, 255, 255, 0.5);

}

#section-config-menuAccScrollDx{
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow-y: auto;
    overflow-x: hidden;
    height: 549px;
    background: #dcdcdc;
    margin: 14px 0px 0px 0px;
    border: none;
    width: 15%;
}

#section-icons-ellipsis{
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: -0.9px;
    o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 6em;
    display:inline;
}

#section-config-AccVideo{
  margin-bottom: 10px;
  border-radius: 4px;
  background:#FFFFFF;
  text-align: left;
  width: 100%;
  font-size: 11px;
}

#section-config-baseTable{
  background: #FFFFFF;
  border-radius: 2px;
  width: 90%;
  max-height: 90%;
  min-height: 80%;
  height: 493px;
  overflow-y: scroll;
}

#section-config-asAccordion{
  border: 1px solid rgba(34,36,38,.15);
  background: white;
  border-radius: 5px;
  font-size: 12px;
}

#section-config-tableTextPosition{
  font-size: 11px;
  background: transparent;
  margin: auto;
  text-overflow: ellipsis;
  text-align: left;
  margin-left: 22px;
}

#section-scrollable-menuAccScrollSx{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: auto;
  overflow-x: hidden;
  height: 440px;
  text-align: left;
  margin:0px -16px 0px 0px;
}

#image-logo-modal{
    height: 50px;
    width: 50px;
    margin: -15px 10px 0px 0px;
}

#confighotspotdetail {
    margin: 5px 5px 5px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 15em;
    text-align: left;
}

#leftmenuitems{
    margin: -500px 0px 0px 0px;
}
.textAreaHidden{
  font-size: 0;
  background: transparent;
  width: unset;
  height: unset;
  border: none;
  margin: 0px 0px 0px -35px;
}
#barTopVideoFrame{
  padding: initial;
  color: #FFFFFF;
}
.borderSchemaLeft{
  border: 1px solid grey;
  background: rgb(38, 47, 64);
  font-size: 1vw;
  margin-top:   0.2vw;
  margin-left:  0.1vw;
  margin-right: 0.1vw;
  margin-bottom:0.0vw;
  border-radius: 2px;
  margin: 0px 0px 0px 0px;
}
.borderSchemaCenter{
  border: 1px solid grey;
  background: rgb(38, 47, 64);
  font-size: 1vw;
  border-radius: 2px;
}
.borderSchemaRight{
  border: 1px solid grey;
  background: rgb(38, 47, 64);
  font-size: 1vw;
  margin: 0px 0px 0px 0px;
  border-radius: 2px;
}
.centerGrid{
  margin: 0px 0px 0px 0px;
  margin-bottom:auto /*0.1vw*/;
  padding: 0vw;
}
.wrapped a {
  white-space: nowrap;
  font-size: 13px; /*era vw per essere adaptive*/
  overflow: hidden;
  text-overflow: ellipsis;
}
#barTopButtonVideo {
  margin: 0px 0px 0px 0px;
}

#listobjectimeline{
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 400px;
  color: #ffffff;
  margin:0px 0px 0px 0px;
 }

 .timelineName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: #ffffff;
  font-size: 12px;
  margin:0px 0px 0px 0px;
 }

 .timelineNameHidden{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: #5f5a5a;
  font-size: 12px;
  margin:0px 0px 0px 0px;
 }
 
#menuCentralDown{
 overflow-y: hidden;
 overflow-x: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
 margin: 0px 0px 0px 0px;
 height: 94%;
}
.addbuttoncolor{
 background-color: #4CAF50; /* Green */
}
.range-box {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 95%;
  position: relative;
  border-radius: 25px;
}

.bg-bar {
  width: 100%;
  max-width: 100%;
  background: #ddd;
  height: 10px;
  border-radius: 25px;
  /*top: 15px;
  position: absolute;
  z-index: 1;*/
}

.range-bar {
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  background: #4d73af;
  left: 100%;
  border-radius: 25px;
}

.range-full {
  width: 100%;
  border-radius: 25px;
  max-width: 100%;
  height: 100%;
  position: absolute;
  background: #4d73af;
  left: 0%;
}

.jumpdiv {
  overflow-x:scroll hidden;
  height:400px ;
}

#jumpsectiondown{
	color: #FFFFFF;
	margin:20px 10px 10px 15px;
}

.jumpsectiondown{
	color: #FFFFFF;
	margin:20px 10px 10px 15px;
}

.form {
  margin: 0 auto;
  padding: 10px 10px 20px 0px;

}

.input-range {
  margin-bottom: 5px;
}
.dimensionfont{
  font-size: 13px;
   display: inline ;
}


.dimensionfont1{
  font-size: 13px;
    ;
}
.borderSchemaRightMentor{
  border: 1px solid grey;
  background: rgb(223, 223, 226);
  font-size: 1vw;
  padding: 10px 10px 10px 0px;
  border-radius: 2px;
}
 .labelRadioTimeline
 {display:"inline" ;
   margin-left:-20px;
  margin-bottom:50px;}


  .dotL {
    height: 10px;
    width: 10px;
    background-color : blue;
    border-radius: 50%;
    border-color:white;
    float: left;
  }
  .dotR {
    height: 10px;
    width: 10px;
    background-color : blue;
    border-radius: 50%;
    border-color:white;
    float: right;
  }
  
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted white;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    /* background-color: #555;
    color: #fff; */
    background-color:#fff;
    color:#555;
    text-align: center;
    border-radius: 6px;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    font-size:12px;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }