/* Created by Vincenzo Centofanti
   Pagina stile ref: HOME.jsx
*/
#section-home {
  display: block;
  width: 100%;
  /*height: 100%;*/
  margin-left: 0px;
}

#section-home-header-search{
  margin: 20px 5px 5px 25px;
}

/*SECTION HOME_CARD */
#section-card-content {
 /* padding: unset;*/
}
#section-card {
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
  margin-right: 10px;
  margin-bottom:  15px;
  margin-left:  10px;
}
#section-card-label {
  background: #ffffff;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing:-0.9px;
  o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin:1px 0px -3px 0px;
  width: 16em;
}

#section-card-label-dimmer {
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  letter-spacing:-0.9px;
  o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  margin:1px 0px -3px 0px;
  width: 16em;
}

#section-card-image {
 max-height:250px ;
  height: 220px;
  max-width: 283px;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 1;
  width: 283px;
  margin: -10px 0px -10px -10px;

}

#section-card-avatar {
  width: 42px;
  height: 42px;
  position:absolute;
  margin:-40px 0px 0px -40px;
  z-index:1;
}

#section-card-iconTime {
  margin:8px 7px 0px 0px;
  float:left;
}

#section-card-iconTitle {
  margin:auto 4px;
}

#section-card-footer {
  background: #ffffff;
  opacity: 1;
  height: 20px;
  font-size: 10px;
  color: #808080;
  word-wrap: break-word;
  margin: 8px 3px 0px 0px;
  letter-spacing: 2px;
}
#section-card-footer-dark {
  background: #242924;
  opacity: 1;
  height: 20px;
  font-size: 10px;
  color: #bbc3bb;
  word-wrap: break-word;
  margin: 8px 3px 0px 0px;
  letter-spacing: 2px;
}
#titlemetadata{
    margin: 0px 0px 0px 0px;
}
#boxmetadata{
  border-color: white;
}

#section-card-dimmer-header {
  margin:0px 0px 180px 0px;
}

#carddimmer {
  white-space: nowrap;
  overflow: hidden;
  o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 16em;
}
/* FINE HOME*/




@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    transform: translatey(0px);
  }
}

.neon {
  position: relative;
  text-shadow: 0 0 40px #ffdd1b;
  font-size: 16px;
  font-weight: bold;
  font-family: "Bungee outline", serif;
/*   color: #ffdd1b; */
  color:#ffff80;
}


@-webkit-keyframes flicker {
  0% {
    text-shadow: 0 0 20px #ffdd1b;
  }
  10% {
    text-shadow: 0 0 30px #ffdd1b;
  }
  20% {
    text-shadow: 0 0 25px #ffdd1b;
  }
  50% {
    text-shadow: 0 0 15px #ffdd1b;
  }
  60% {
    text-shadow: 0 0 30px #ffdd1b;
  }
  80% {
    text-shadow: 0 0 15px #ffdd1b;
  }
  90% {
    text-shadow: 0 0 20px #ffdd1b;
  }
  100% {
    text-shadow: 0 0 25px #ffdd1b;
  }
}

#home_category_banner_category{
	text-align: left;
	width:100%;
	font-variant: small-caps;
	text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
	font-size: 32px;
	color: white;
	height:60px;
	border: 1px solid black;
	margin: 20px 0px 10px 0px; /* top | right | bottom | left */
	background:linear-gradient(200deg, #2A5491, #221f1f, #444444);
	padding-top: 5px;
	padding-right: 30px;
	padding-bottom: 0px;
	padding-left: 10px;
}
#colorhometable
{
  color:linear-gradient(200deg, #2A5491, #221f1f, #444444);
}

