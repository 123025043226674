/* Created by Vincenzo Centofanti  
   Pagina stile ref:  ABOUT.jsx*/

#section-about-image{
  width:95%;
  margin:-9px 0px 0px 30px;
  color:#FFFFFF ;
}

#section-about-header{
  color:#FFFFFF ;
}

#section-about-text{
  background: #ffffff;
  color:#FFFFFF ;
}

#section-resource-textarea{
  width:100%;
  margin: 0px 0px 10px 5px;
  border-radius: 5px;
  font-size: 16px;
  color:#FFFFFF ;
}

#section-iframe{
    width:100%;
    margin: 5px 5px 5px 5px;
    padding: 0px;
    font-size: 16px;
    color:#FFFFFF ;
}

#section-footer-EU {
  width: 100px;
  color:#FFFFFF ;
}

#section-footer-logo {
  width: 50px;
  margin: -15px 15px 0px 0px;
  color:#FFFFFF ;
}

#section-footer-text {
  margin: 8px;
  color:#FFFFFF ;
}

#section-about-segment {
  margin: -12px 125px 70px 136px;
  color:#FFFFFF ;
}

/* FINE ABOUT*/