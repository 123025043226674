.boxdiv{
    margin:0px 0px 0px 0px;
}

.tabbardiv{
    margin:5px 5px 5px 5px;
    text-align: left;
    color: #FFFFFF;
}

.tabbarsubdiv{
    margin:5px 5px 5px 5px;
    text-align: left;
    color: #FFFFFF;
}

.tabbarsubdivcontent{
    margin:5px 5px 5px 5px;
    text-align: right;
    color: #FFFFFF;
}

#hotspotdetail {
    white-space: nowrap;
    overflow: hidden;
    o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 20em;
    text-align: right;
}

#iframeboxplayer{
    width: 100%;
    margin:0px 0px 0px 0px;
    /*height: 490px;*/
}

#iframeObjplayer{
    margin: 0px 0px 0px 0px;
    padding: 0px;
    width: 100%;
    height: 80vh;
}

#master-labelplayer {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin:1px 1px 1px 1px;
    width: 16em;
}