section-content-baseTable/* Created by Vincenzo Centofanti  
   Pagina stile ref-Directory: UPLOAD
                                  |
                                  |__upload.jsx
                                  |__other.jsx
                                  |__resource.jsx
                                  |__video.jsx    */
/*UPLOAD*/
#section-content-generic {/**/}
/*UPLOAD-OTHER*/
#section-content-headertext {
	color:#EBEBF0;
}
/*UPLOAD-RESOURCE*/
#section-content-resource-step {
  margin: 0px 0px 0px 0px;
}
/*UPLOAD-VIDEO*/
#section-content-video-step {
  margin: 0px 0px 0px 0px;
}

#section-content-text{
 color:#000000; 
}

#section-content-tableshape {
  margin: 0px 0px 0px 0px;
}

#section-content-deleteName{
  margin: 0px 0px 0px 10px;
  font-size: 20px;
}

#ModalTempUpload{
  margin: auto;
}

#section-content-menutabular{
  border: none;
  border-radius: 2px;
  margin: 57px 0px 0px 0px;
}

#section-content-tableHeader{
  background: #EBEBF0;
  color:#000000; 
}

#section-content-modalHeader{
  background: #EBEBF0;
}

#section-content-textArea{
margin:0px 10px 0px -130px;
}

#pulseUpload {
  animation: upload 5s infinite;
  color:white; 
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes upload {
    0%   {color:white; }
    50%  {color:black; }
    100% {color:white; }
}

/* Standard syntax */
@keyframes upload {
    0%   {color:white; }
    50%  {color:black; }
    100% {color:white; }
}


#section-content-baseTable{
  background: #FFFFFF;
  border-radius: 2px;
  width: 100%;
  max-height: 90%;
  min-height: 80%;
  height: 300px;
  overflow-y: scroll;
}


#section-content-footerTable{
  /*background: #EBEBF0;*/
  background: rgba(0,0,0,0.3);
  width: 100%;
  margin:5px 0px 6px 0px;

}

#section-content-headerTable{
  background: #EBEBF0;
  width: 100%;
  margin:5px 0px 6px 0px;
}

.modal-custom-overlay {
  /*background: rgba(36, 123, 160, 0.9); /*SAME COLOR*/
  /*background: rgba(255, 255, 255, 0.3); /*WHITE*/
  background: rgba(0, 0, 0, 0.9); /*BLACK*/
}

.modal-custom-modal {
  /*background: #283E51;  /*BODY*/
  background: rgb(0,0,0);  /*HEADER*/
  max-width: 800px;
  width: 100%;
  border-radius:5px;
}

.styles_overlay__CLSq- {
  background: rgba(128, 128, 128, 0.90);
}
/* FINE CONTENT*/