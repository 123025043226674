.modalContainer {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}


.preview-media-container {
    display: block;
    justify-content: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: -30px;
    margin-bottom: 10px;
    width: 480px;
}

.preview-video-container {
  display: block;
  justify-content: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: -30px;
  margin-bottom: auto;
  width: 480px;
  height: 280px;
  }

  .preview-mentor-container {
    display: inline;
    /* justify-content: auto; */
    /* margin-left: auto;
    margin-right: auto; */
    /* margin-top: -30px; */
    /* margin-bottom: auto; */
    width: 150px;
    height: 100px;
    }