/* Created by Vincenzo Centofanti  
   Pagina stile ref: PROFILE.jsx
*/
#section-profile {
  width: 70%;
  margin: 5% auto;
}
#section-spacebtw {
  margin:10px 10px 10px 10px;
}
#section-profile-avatar {
  margin:10px 10px 10px 10px;
  width: 84px;
  height: 84px;
}

#section-profile-step{
  margin:0px 0px 0px 60px;
}

#section-profile-header{
  margin:0px 0px 10px 40px;
}
#section-profile-header-search{
  margin-right: auto;
  margin-bottom: auto;
  margin-top: 50px;
  margin-left: auto;
}
#section-profile-divider {
  margin:-10px 0px 0px 0px;
}
/* FINE PROFILE*/